import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import Img from 'gatsby-image'
import { s, colors, alpha, globals } from '../../style'
import { WrapperSection } from '../../wrappers'
import { string_to_slug } from '../../helpers'
import SideLine from '../../elements/SideLine/SideLine'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ScrollDots } from '../../elements'

const BlogMinis = ({ theme, data, ...props }) => {
  const { slug_section, title } = data
  const query = useStaticQuery(graphql`
    {
      blogs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/blog/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              url
              language
              blog_slug
              author
              description
              date
              image {
                name
                publicURL
                childImageSharp {
                  fluid(maxWidth: 600, quality: 100, toFormat: JPG) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }

      authors: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/authors/" } }
      ) {
        edges {
          node {
            frontmatter {
              title
              subtitle
              description
              image {
                name
                childImageSharp {
                  fluid(maxWidth: 300, quality: 100, toFormat: JPG) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  // const authors = query.authors.edges.map(({ node }) => node.frontmatter)
  const blogs = query.blogs.edges.map(({ node }) => node.frontmatter)
  const refBlogs = useRef(null)
  // const onScreen = useOnScreen(refBlogs, '0px')

  useEffect(() => {
    if (refBlogs.current)
      setTimeout(() => refBlogs?.current?.scrollTo({ left: 1 }), 0)
  }, [])
  // console.log(authors)
  // console.log(blogs)

  return (
    <>
      <WrapperSection
        id={slug_section}
        noPaddingTop
        outside={<SideLine theme={theme}>{title}</SideLine>}></WrapperSection>

      <div css={sBlogs}>
        <ScrollContainer
          nativeMobileScroll
          innerRef={refBlogs}
          css={[
            sBlogsContainer,
            // { [s.md]: { width: features.length * 460 } },
            // features.length <= 3
            //   ? { [s.md]: { justifyContent: 'center' } }
            //   : { [s.md]: { marginLeft: 100 } },
          ]}>
          {blogs
            .sort((a, b) => b.date - a.date)
            .filter((blog) => blog.image)
            .map((blog, id) => (
              <div css={{ padding: '0 1.5rem' }} key={id}>
                <Link
                  to={`/${
                    blog.blog_slug ? blog.blog_slug : string_to_slug(blog.title)
                  }`}
                  key={id}
                  css={[
                    sBlog,
                    sBlogTheme(theme),
                    // !blog.blog_slug && { filter: 'grayscale(1)', opacity: 0.3 },
                  ]}>
                  <div css={sImage}>
                    {blog.image?.childImageSharp?.fluid && (
                      <Img
                        style={sImage.image}
                        fluid={blog.image.childImageSharp.fluid}
                      />
                    )}
                  </div>
                  <div css={sTitle}>{blog.title}</div>
                  <div css={sDescription}>{blog.description}</div>
                </Link>
              </div>
            ))}
        </ScrollContainer>
      </div>

      <ScrollDots
        refElement={refBlogs}
        arr={blogs.filter((blog) => blog.image)}
        extraCss={sDots(theme)}
      />
    </>
  )
}

const sBlogs = {
  display: 'flex',
  overflowX: 'auto',
  padding: '2rem 0rem',
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}

// const sHeadline = {
//   [s.sm_down]: {
//     width: '100%',
//     textAlign: 'center',
//     marginBottom: '0rem',
//   },
//   [s.md]: { padding: '0 2rem', marginBottom: '2rem' },
// }

const sBlog = {
  transition: 'transform 0.25s ease-out, box-shadow 0.25s ease-out',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'stretch',
  flexShrink: 0,
  width: 300,
  height: '100%',
  borderRadius: 24,
  [s.xs]: {
    width: `calc(100vw - ${globals.spacing.inside.mobile * 2}px)`,
  },
  paddingTop: '1.5rem',
  boxShadow: `0px 8px 16px ${colors.black.concat(alpha[4])}`,
  '&:hover': {
    [s.hover]: {
      transform: 'scale(1.05) translateY(-16px)',
      boxShadow: `0px 8px 32px ${colors.black.concat(alpha[16])}`,
    },
  },
}

const sBlogTheme = (theme) => ({
  backgroundColor: colors[theme].container.concat(alpha[30]),
})
const sDots = (theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 0 2rem',
  },
  dot: {
    width: 12,
    height: 12,
    borderRadius: 6,
    backgroundColor: colors[theme].main,
  },
  inactive: {
    cursor: 'pointer',
    transition: 'opacity 0.2s ease-in-out',
    opacity: 0.16,
    padding: `0 6px`,
    '&:hover': {
      [s.hover]: {
        opacity: 0.6,
      },
    },
  },
  active: {
    [s.sm_down]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
  activeDesktop: {
    [s.md]: {
      opacity: 1,
      '&:hover': {
        [s.hover]: {
          opacity: 1,
        },
      },
    },
  },
})
const sImage = {
  position: 'relative',
  width: 200,
  height: 200,
  margin: '0 auto',
  borderRadius: '50%',

  image: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    borderRadius: 'inherit',
    // paddingTop: '100%',
  },
}

const sTitle = {
  fontSize: 14,
  letterSpacing: -0.3,
  minHeight: 44,
  fontWeight: 700,
  margin: '1.5rem 1.5rem 1rem',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}
const sBlogsContainer = {
  display: 'flex',
  overflowX: 'auto',
  [s.md]: { padding: '3rem 2rem 4rem' },
  // overflowY: 'hidden',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'stretch',
}
const sDescription = {
  fontSize: 12,
  letterSpacing: 0,
  fontWeight: 400,
  margin: '0rem 1.5rem 1.5rem',
  opacity: 0.3,
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
}

export default BlogMinis
